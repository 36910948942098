//export const FAIRERS_URL = "https://demo.fairers.com";
//export const FAIRERS_URL = "https://stage.fairers.com";
//export const FAIRERS_URL = "https://newchanger.fairers.com";

//export const API_URL = "https://api-demo.fairers.com";
//export const API_URL = "https://api-stage.fairers.com";
//export const API_URL = "https://api-newchanger.fairers.com";

//LOACL API
//export const API_URL = "/api";

export const FAIRERS_URL = "https://bhangola.fairers.com";
export const API_URL = "https://api-bhangola.fairers.com";